<template>
  <div class="wrapper">
    <div class="info">
      <div class="title">
        APPS THAT CARE
      </div>
      <div class="description">
        <b> Who are we?</b><br/>
        We are a creative team specializing in the development of mobile applications with a primary focus on healthcare and utilities. Our passion for innovation and technology places us at the forefront of solving user problems.<br/>
        <b>What are we doing?</b><br/>
        We create cutting-edge mobile applications aimed at improving health and everyday life. Our goal is to provide innovative solutions to address issues and make a positive impact in the fields of medicine and utilities.<br/>
        <b>Our goal</b><br/>
        We create cutting-edge mobile applications aimed at improving health and everyday life. Our goal is to provide innovative solutions to address issues and make a positive impact in the fields of medicine and utilities.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "temp",
}
</script>
<style lang="less">
@import "style.less";
</style>